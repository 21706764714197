import { useState } from 'react'
import { Tab } from '@headlessui/react'

import { Fade } from 'react-awesome-reveal'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import male1 from '../assets/NibiruKnight.png'

import male2 from '../assets/Marauder.png'

import female1 from '../assets/Female2.png'

import female2 from '../assets/Female1.png'
import OriginModal from './OriginModal'

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function Description() {
	let [categories] = useState({
		Vision: [
			{
				id: 1,
				paragraph:
					'We are creating a collection to unite the Metaverse. Our team is made up of like-minded artists, developers and creators who all share the same dream of Decentralized open worlds and cross-reality experiences. We came together to create the highest level custom 3D avatars and to empower a community by paving an avenue for developers & artists to participate in creating the Metaverse with us.',
				image: male1
			}
		],
		Launch: [
			{
				id: 2,
				paragraph:
					"Vol. 1 of MetaTravelers will showcase 7777 avatars of the Nibiru guild. The guild will be composed of both male and female 3D avatars who will have shared traits across the collection. We are utilizing Chainlink VRF for on-chain verification of randomness. Nibiru characters are created with a unique DNA sequence and then auto generated onto each MetaTraveler at the time of minting. These NFT's will also act as an access key to unlock future events, discord channels and gamified experiences in the Metaverse.",
				image: female2
			}
		],
		Community: [
			{
				id: 3,
				paragraph:
					'Our goal is to create a community who will explore this new world and the untapped potential of the Metaverse. By creating one of the largest treasuries in the space (20% of all sales) The Nibiru will fund artists, developers and creators of the guild to come together and write the next chapter of the Metaverse. We will continually support creators to build experiences and pursue collaborations for Metaverse games, AR/VR technology and cross-reality experiences as well as other events such as scavenger hunts and modding contests. Co-creating with the community will allow members to be part of the history of the Metaverse opposed to spectators of it.',
				image: male2
			}
		],
		Future: [
			{
				id: 4,
				paragraph:
					'The future of this collaboration will give birth to a universe of guilds including the Nova and Theora which we look to launch in early 2022. Each guild will showcase a unique set of MetaTravelers from distant worlds as we build the Metaverse together, one step at a time. These guilds will operate independently from each other at first but will ultimately benefit from one another as the larger MetaTravelers communities grow where guilds will be able to collaborate, compete and create in a vast network across the Metaverse. We have many surprises in store for long term holders including airdrops and additional benefits from staking which will be released in the coming weeks. We will be continuously developing new features and providing further utility for each guild member. We look forward to building the Metaverse with you all!',
				image: female1
			}
		]
	})

	return (
		<div id='our-vision' className='desc-parallax '>
			<div className='arrow-down'></div>
			<div className='relative flex justify-center px-4 pt-32 mx-auto md:pt-52 lg:pt-72 max-w-7xl sm:px-6 lg:px-8'>
				<div className='w-full px-2 py-16 max-w-7xlxl sm:px-0 '>
					<Tab.Group>
						<Tab.List className='flex flex-col p-1 space-x-1 md:flex-row rounded-xl'>
							{Object.keys(categories).map(category => (
								<Tab
									key={category}
									className={({ selected }) =>
										classNames(
											'  w-full py-2.5 text-xl md:text-3xl leading-5 font-medium mb-4',
											'',
											selected
												? ' orbitron text-white border-b-2'
												: 'orbitron-no-glow text-gray-400'
										)
									}
								>
									<div className='pb-2'>{category}</div>
								</Tab>
							))}
						</Tab.List>
						<Tab.Panels className='mt-2'>
							{Object.values(categories).map((posts, idx) => (
								<Tab.Panel key={idx}>
									<ul>
										{posts.map(post => (
											<div
												className='grid grid-cols-1 lg:grid-cols-2 '
												key={post.id}
											>
												<Fade delay={140}>
													<div>
														<div
															className='px-6 py-8 text-base leading-7 text-gray-300 card-mixin desc-mixin '
															data-augmented-ui='tr-clip bl-clip br-clip-y both'
														>
															{post.paragraph}
														</div>
													</div>
												</Fade>
												<Fade delay={400}>
													<div className='flex justify-center'>
														<LazyLoadImage
															className='px-8'
															src={post.image}
															effect='blur'
															alt='MetaTraveler'
														/>
													</div>
												</Fade>
											</div>
										))}
									</ul>
								</Tab.Panel>
							))}
						</Tab.Panels>
					</Tab.Group>
				</div>
			</div>
			<div className='relative flex justify-center px-4 pb-32 mx-auto md:pb-52 lg:pb-72 max-w-7xl sm:px-6 lg:px-8'>
				<div className='flex justify-center w-full px-2 pt-24 pb-16 max-w-7xlxl sm:px-0'>
					<Fade direction='up' delay={300} triggerOnce>
						<div
							className='max-w-3xl px-32 py-14 controls-mixin'
							data-augmented-ui='  bl-clip br-clip-y tr-clip  tl-clip-y  both'
						>
							<h2 className='py-6 text-3xl font-extrabold text-center text-white sm:text-4xl orbitron'>
								Nibiru Motion Comics
							</h2>
							<OriginModal />
						</div>
					</Fade>
				</div>
			</div>
		</div>
	)
}
