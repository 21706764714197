import { Link } from 'react-router-dom'

import NDSLogo from '../assets/NDSLogo.png'
import MTLogo from '../assets/mtText.png'

import '../v2.css'

const roles = [
	{
		title: 'Smart Contract Developer',
		link: 'smart-contract-developer'
	},
	{
		title: '3D Generalist',
		link: '3d-generalist'
	},
	{
		title: 'Unreal Engine Developer',
		link: 'unreal-engine-developer'
	},
	{
		title: 'Community Manager',
		link: 'community-manager'
	}
]

export default function JoinTheTeam() {
	return (
		<div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
			<div className='flex justify-between pt-8'>
				<Link to='/'>
					<img src={MTLogo} alt='MetaTravelers' className='h-8' />
				</Link>
				<a
					href='https://www.nextdimensionstudios.com/'
					target='_blank'
					rel='noopener noreferrer'
				>
					<img
						className='h-12'
						src={NDSLogo}
						alt='Next Dimension Studios'
					/>
				</a>
			</div>
			<div className='py-28'>
				<div className='text-5xl font-semibold tracking-widest text-white orbitron-no-glow'>
					JOIN THE TEAM
					<div className='mt-1 join-the-team-rect'></div>
				</div>
			</div>
			<div>
				<div>
					{roles.map(role => (
						<div className='flex justify-between py-8'>
							<div className='text-2xl font-bold tracking-wider text-white orbitron-no-glow'>
								{role.title}
							</div>
							<Link
								to={role.link}
								className='px-4 py-5 font-bold tracking-widest text-white general-button orbitron-no-glow'
							>
								Apply
							</Link>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
