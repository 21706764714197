import { Link } from 'react-router-dom'
import { PopupButton } from '@typeform/embed-react'

export default function CommunityManager() {
	return (
		<div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
			<div className='flex justify-between pt-8'>
				<Link
					to='/join-the-team'
					className='text-xl font-semibold tracking-wide text-white transition-all duration-200 cursor-pointer orbitron-no-glow hover:text-gray-300'
				>
					Go back
				</Link>
			</div>
			<div className='py-28'>
				<div className='flex justify-between'>
					<div className='text-5xl font-semibold tracking-widest text-white orbitron-no-glow'>
						Community Manager
					</div>
					<PopupButton
						id='fFBNTTAU'
						className='px-4 py-5 my-auto font-bold tracking-widest text-white general-button orbitron-no-glow'
					>
						Apply Now
					</PopupButton>
				</div>
				<div className='text-gray-300'>
					<div className='pt-16 '>
						MetaTravelers is looking for passionate Community
						Managers! Join the MetaTravelers Team in an essential
						role to promote a welcoming space for all community
						members and help educate new members on what the
						MetaTravelers project is all about.
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Role type:
						</span>
						<br />
						Contract to start, with potential to grow into a
						full-time position as the organization grows.
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Required Skills:
						</span>
						<br />
						Maintaining and encouraging a positive and grounded
						active presence in the community. Familiarity with
						decentralized technology and the NFT space. Experience
						demonstrating understanding and compassion toward the
						community, and being particularly adept and tactful at
						responding to community members.
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Additional Desired Skills and Experience in:
						</span>
						<br />- Prior experience managing crypto-based
						communities <br />- Being able to research and being
						able to build partnerships with other projects and
						communities
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Responsibilities and Duties:
						</span>
						<br />
						- Strategize new and innovative ways to build and
						maintain our existing community
						<br />
						- Organize and host events regularly in our discord
						server, web, and virtual spaces
						<br />- Engage with the community via our other social
						media platforms
					</div>
				</div>
			</div>
		</div>
	)
}
