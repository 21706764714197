import ReactPlayer from 'react-player/lazy'
// import videoLoop from '../assets/videoLoop.mp4'
import HYPE_NIBIRU_2 from '../assets/HYPE_NIBIRU_2.mp4'
import mtText from '../assets/mt_logos/mt_lockup-color-glow.png'
import { Fade } from 'react-awesome-reveal'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import HuntClue from './HuntClue'

export default function Hero() {
	// const { scrollYProgress } = useViewportScroll()
	// const scaleAnim = useTransform(
	// 	scrollYProgress,
	// 	[0, 0.1, 0.6],
	// 	[1, 0.6, 0.4]
	// )
	// useViewportScroll, useTransform
	// <motion.div style={{ scale: scaleAnim }}></motion.div>
	const { scrollYProgress } = useViewportScroll()
	const scaleAnim = useTransform(
		scrollYProgress,
		[0, 0.1, 0.6],
		[1, 0.6, 0.4]
	)
	return (
		<motion.div
			className='flex justify-center'
			style={{ scale: scaleAnim }}
		>
			<div id='home' className='relative w-screen h-screen '>
				{/* small screen */}
				<div className='block py-32 lg:hidden bg-pic'>
					<div className='flex justify-center'>
						<Fade direction='up' triggerOnce>
							<img
								src={mtText}
								className='w-auto sm:h-52'
								alt='Meta Travelers'
							/>
						</Fade>
					</div>

					{/* <div className='pt-20 text-2xl font-extrabold tracking-tight text-center text-white orbitron'>
						Embody your avatar, explore the Metaverse.
					</div> */}
					{/* <div className='flex justify-center'>
						<Link to='/contest'>
							<motion.button
								whileHover={{ boxShadow: '0 0 8px #fff' }}
								className='h-20 px-8 mt-16 cta-button orbitron'
							>
								Learn More
							</motion.button>
						</Link>
					</div> */}
				</div>
				{/* large screen */}
				<div className='hidden overflow-hidden lg:block'>
					<div
						className='absolute min-w-full min-h-full overflow-hidden left-1/2 top-1/2'
						style={{ transform: 'translate(-50%, -50%)' }}
					>
						<ReactPlayer
							style={{
								opacity: '0.6'
							}}
							url={HYPE_NIBIRU_2}
							playing
							loop
							muted
							width='100%'
							height='100%'
						/>
					</div>
					<HuntClue />
					<div className='absolute flex flex-col items-center justify-center w-full h-full xl:pt-96'>
						{/* <Fade direction='up' triggerOnce>
							<img
								src={mtText}
								className='object-scale-down w-auto h-80 2xl:h-96'
								alt='Meta Travelers'
							/>
						</Fade> */}
						{/* <div className='text-3xl font-extrabold tracking-wider text-center text-white 2xl:text-5xl orbitron-glow-black'>
								Embody your avatar, explore the Metaverse.
							</div> */}
						{/* <div className='pt-4 text-3xl font-extrabold tracking-tight text-center text-white 2xl:text-5xl orbitron'>
								Explore The Metaverse
							</div> */}

						{/* <Fade direction='up' delay={500} triggerOnce>
							<Link to='/contest'>
								<motion.button
									whileHover={{ boxShadow: '0 0 8px #fff' }}
									className='h-20 px-8 mt-16 cta-button orbitron'
								>
									Learn More
								</motion.button>
							</Link>
						</Fade> */}
					</div>
				</div>
			</div>
		</motion.div>
	)
}
