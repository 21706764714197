import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ethLogo from '../assets/ethLogo.svg'

import nibiruLogo from '../assets/nibiruLogo.svg'

import nibiruSymbol from '../assets/nibiruSymbol.svg'

export default function Stats() {
	return (
		<div className='relative py-24 bg-indigo-900 '>
			<div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
				<img
					loading='lazy'
					src={nibiruSymbol}
					alt='nibiru symbol'
					style={{ width: '700px' }}
					className='absolute top-0 z-0 hidden transform -translate-x-1/2 lg:block opacity-20 left-full -translate-y-2/4 lg:left-auto lg:right-full lg:translate-x-3/4 lg:translate-y-1/4'
				/>
				<div className='justify-center max-w-4xl mx-auto text-center'>
					<div className='flex justify-center pb-24'>
						<LazyLoadImage
							effect='blur'
							src={nibiruLogo}
							alt='nibiru logo'
							className='object-contain h-48 '
						/>
					</div>
					<h2 className='relative z-10 pb-6 text-3xl font-extrabold text-white sm:text-4xl orbitron-no-glow'>
						MetaTravelers Vol. 1: The Nibiru
					</h2>
				</div>
				<VisibilitySensor>
					{({ isVisible }) => (
						<dl className='relative z-10 mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8'>
							<div className='flex flex-col'>
								<dt className='order-2 mt-2 text-lg font-medium leading-6 text-gray-300'>
									Avatars
								</dt>
								<dd className='order-1 hidden text-5xl font-extrabold text-white orbitron-no-glow md:block'>
									<CountUp
										duration={1}
										end={isVisible ? 7777 : 0}
									/>
								</dd>
								<dd className='order-1 block text-5xl font-extrabold text-white md:hidden orbitron-no-glow'>
									7777
								</dd>
							</div>
							<div className='flex flex-col mt-10 sm:mt-0'>
								<dt className='order-2 mt-2 text-lg font-medium leading-6 text-gray-300'>
									Unique Traits
								</dt>
								<dd className='order-1 hidden text-5xl font-extrabold text-white orbitron-no-glow md:block'>
									<CountUp
										duration={1}
										end={isVisible ? 150 : 0}
									/>
								</dd>
								<dd className='order-1 block text-5xl font-extrabold text-white md:hidden orbitron-no-glow'>
									150
								</dd>
							</div>
							<div className='flex flex-col mt-10 sm:mt-0'>
								<dt className='order-2 mt-2 text-lg font-medium leading-6 text-gray-300'>
									Possible Combinations
								</dt>

								<dd className='order-1 block text-5xl font-extrabold text-white orbitron-no-glow'>
									{'>'}1 billion
								</dd>
							</div>
						</dl>
					)}
				</VisibilitySensor>
			</div>
			<div className='mt-10 text-center sm:max-w-7xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-4'>
				<div className='flex flex-col flex-grow max-w-xl m-10 height-100 '>
					<div className='flex-1 px-6 pt-16 pb-8 md:px-8'>
						<div
							className='top-0 inline-block p-5 transform -translate-y-1/2 custom-button'
							data-augmented-ui=' br-clip tl-clip  '
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='w-10'
								viewBox='0 0 20 20'
								fill='#fff'
							>
								<path
									fillRule='evenodd'
									d='M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z'
									clipRule='evenodd'
								/>
							</svg>
						</div>
						<h3 className='text-xl font-semibold tracking-wide text-gray-200 uppercase orbitron-no-glow'>
							Metaverse Access
						</h3>
						<p className='mt-4 text-base leading-6 text-gray-300'>
							All guild members will have exclusive access to
							future events and games throughout the Metaverse.
						</p>
					</div>
				</div>
				<div className='flex flex-col flex-grow max-w-xl m-10 height-100 '>
					<div className='flex-1 px-6 pt-16 pb-8 md:px-8'>
						<div
							className='top-0 inline-block p-5 transform -translate-y-1/2 custom-button'
							data-augmented-ui=' br-clip tl-clip  '
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='w-10'
								viewBox='0 0 20 20'
								fill='#fff'
							>
								<path
									fillRule='evenodd'
									d='M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z'
									clipRule='evenodd'
								/>
							</svg>
						</div>
						<h3 className='text-xl font-semibold tracking-wide text-gray-200 uppercase orbitron-no-glow'>
							Own the IP
						</h3>
						<p className='mt-4 text-base leading-6 text-gray-300'>
							All guild members own commercial rights of their
							character for as long as they retain ownership.
						</p>
					</div>
				</div>
				<div className='flex flex-col flex-grow max-w-xl m-10 height-100 '>
					<div className='flex-1 px-6 pt-16 pb-8 md:px-8'>
						<div
							className='top-0 inline-block p-5 transform -translate-y-1/2 custom-button'
							data-augmented-ui=' br-clip tl-clip  '
						>
							<img
								src={ethLogo}
								alt='Ethereum logo'
								className='w-10'
							/>
						</div>
						<h3 className='text-xl font-semibold tracking-wide text-gray-200 uppercase orbitron-no-glow'>
							ERC-721
						</h3>
						<p className='flex-grow mt-4 text-base leading-6 text-gray-300'>
							All MetaTravelers are secured as ERC-721 tokens on
							the Ethereum blockchain.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
