import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import roadmap from '../assets/roadmap.png'

export default function Roadmap() {
	return (
		<div id='roadmap'>
			<div className='relative md:hidden'>
				<a href={roadmap} target='_blank' rel='noreferrer'>
					<LazyLoadImage
						effect='blur'
						src={roadmap}
						alt='roadmap'
						className='relative w-full opacity-20'
					/>

					<div className='absolute w-full text-center text-white top-1/2'>
						Click to open the roadmap in a new tab
					</div>
				</a>
			</div>

			<div></div>
			<div className='hidden md:block'>
				<LazyLoadImage src={roadmap} effect='blur' alt='roadmap' />
			</div>
		</div>
	)
}
