import Scramble from 'react-scramble'

import VisibilitySensor from 'react-visibility-sensor'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import leapn from '../assets/partners/leapn.png'
import netvrk from '../assets/partners/netvrk.png'
import nanopass from '../assets/partners/nanopass.png'

const partners = [
	{
		name: 'leapn',
		imageUrl: leapn,
		link: 'https://leapn.life/'
	},
	{
		name: 'netvrk',
		imageUrl: netvrk,
		link: 'https://www.netvrk.co/'
	},
	{
		name: 'nanopass',
		imageUrl: nanopass,
		link: 'https://www.nanopass.io/'
	}
]

export default function Partners() {
	return (
		<div id='team'>
			<div className='relative px-4 pt-10 pb-32 mx-auto md:pb-52 lg:pb-72 max-w-7xl sm:px-6 lg:px-8'>
				<div className='pb-24 text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl orbitron'>
					<VisibilitySensor>
						{({ isVisible }) => (
							<Scramble
								autoStart={isVisible}
								text='Partners'
								steps={[
									{
										roll: 10,
										action: '+',
										type: 'all'
									},
									{
										action: '-',
										type: 'forward'
									}
								]}
							/>
						)}
					</VisibilitySensor>
				</div>
				<div className='flex justify-center'>
					<div>
						<ul className='space-y-12 lg:grid lg:grid-cols-3 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0'>
							{partners.map(partner => (
								<li key={partner.name}>
									<div className='flex justify-center'>
										<a
											href={partner.link}
											target='_blank'
											rel='noreferrer'
										>
											<LazyLoadImage
												effect='blur'
												src={partner.imageUrl}
												alt={partner.name}
											/>
										</a>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}
