import { Link } from 'react-router-dom'
import { PopupButton } from '@typeform/embed-react'

export default function ThreeDGeneralist() {
	return (
		<div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
			<div className='flex justify-between pt-8'>
				<Link
					to='/join-the-team'
					className='text-xl font-semibold tracking-wide text-white transition-all duration-200 cursor-pointer orbitron-no-glow hover:text-gray-300'
				>
					Go back
				</Link>
			</div>
			<div className='py-28'>
				<div className='flex justify-between'>
					<div className='text-5xl font-semibold tracking-widest text-white orbitron-no-glow'>
						3D Generalist
					</div>
					<PopupButton
						id='gUIIlln5'
						className='px-4 py-5 my-auto font-bold tracking-widest text-white general-button orbitron-no-glow'
					>
						Apply Now
					</PopupButton>
				</div>
				<div className='text-gray-300'>
					<div className='pt-16 '>
						MetaTravelers is seeking a stellar 3D Generalist with a
						particular emphasis on Animation/Motion Design! Join the
						MetaTravelers Team to help us share all the
						groundbreaking 3D utility we are bringing to the
						MetaTravelers Universe.
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Role type:
						</span>
						<br />
						Contract to start, with potential to grow into a
						full-time position as the organization grows.
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Required Skills:
						</span>
						<br />
						Professional experience in character rigging and
						animation/motion design, using one or more of the
						following:
						<br />- Cinema4D
						<br />- Blender
						<br />- Maya
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Additional Desired Skills and Experience in:
						</span>
						<br />- Python scripting
						<br />- Octane render experience
						<br />- Unreal Engine experience
						<br />- Game development pipeline experience
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Responsibilities and Duties:
						</span>
						<br />
						- Complete character rigging and animation tasks, which
						includes skinning/weighting/setting up control rigs
						following creative direction from leadership and other
						3D team members
						<br />
						- Contribute and brainstorm animation ideas for
						promotional material
						<br />
						- Demonstrate clear and collaborative communication
						within the 3D and concepting team and marketing teams
						<br />
						- Attendance and participation in daily/weekly status
						meetings
						<br />- Ability to receive and respond to constructive
						criticism regarding art direction and vision
						<br />- Timely completion of tasks/be able to respond to
						and address urgent tasks with a quick turnaround to meet
						high priority deadlines
					</div>
				</div>
			</div>
		</div>
	)
}
