import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

import Scramble from 'react-scramble'

import VisibilitySensor from 'react-visibility-sensor'

import { Fade } from 'react-awesome-reveal'

import nbru_one from '../assets/nbru_one.png'
import nbru_three from '../assets/nbru_three.png'

const faqs = [
	{
		question: 'Can I wear my avatar in the Metaverse?',
		answer: 'Absolutely, we have designed this collection with the Metaverse in mind. All NFT collectors will be given a game-ready 3D file of their character which can then be minted as a wearable in Metaverse worlds such as Decentraland, Somnium Space and others.'
	},
	{
		question: 'Will there be more guilds made in the future?',
		answer: 'Yes, we are building out a universe of guilds from different worlds of the Metaverse. Each MetaTraveler guild will be its own collection with unique traits and designs.'
	}
]

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function FAQ() {
	return (
		<div id='faq' className='relative faq-parallax'>
			<div className='relative px-4 py-32 mx-auto md:py-52 lg:py-72 max-w-7xl sm:px-6 lg:px-8'>
				<div>
					<div className='pb-24 text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl orbitron'>
						<VisibilitySensor>
							{({ isVisible }) => (
								<Scramble
									autoStart={isVisible}
									text='FAQ'
									steps={[
										{
											roll: 10,
											action: '+',
											type: 'all'
										},
										{
											action: '-',
											type: 'forward'
										}
									]}
								/>
							)}
						</VisibilitySensor>
					</div>
					<div className='max-w-2xl mx-auto '>
						<Fade direction='up' triggerOnce>
							<div
								className='relative max-w-3xl py-20 text-lg leading-6 text-gray-300 px-14 card-mixin mint-mixin'
								data-augmented-ui=' t-clip-x tr-clip  b-clip-x bl-clip  r-clip-y l-clip-y  both'
							>
								<dl className='space-y-6 divide-y divide-gray-200'>
									{faqs.map(faq => (
										<Disclosure
											as='div'
											key={faq.question}
											className='pt-6 '
										>
											{({ open }) => (
												<>
													<dt className='text-lg'>
														<Disclosure.Button className='flex items-start justify-between w-full text-left text-gray-300'>
															<span className='font-medium text-white '>
																{faq.question}
															</span>
															<span className='flex items-center ml-6 h-7'>
																<ChevronDownIcon
																	className={classNames(
																		open
																			? '-rotate-180'
																			: 'rotate-0',
																		'h-6 w-6 transform transition duration-150 ease-in-out'
																	)}
																	aria-hidden='true'
																/>
															</span>
														</Disclosure.Button>
													</dt>
													<Disclosure.Panel
														as='dd'
														className='pr-12 mt-2'
													>
														<p className='text-base text-gray-300'>
															{faq.answer}
														</p>
													</Disclosure.Panel>
												</>
											)}
										</Disclosure>
									))}
									<Disclosure as='div' className='pt-6 '>
										{({ open }) => (
											<>
												<dt className='text-lg'>
													<Disclosure.Button className='flex items-start justify-between w-full pb-4 text-left text-gray-300'>
														<span className='font-medium text-white'>
															How do I purchase a
															MetaTraveler?
														</span>
														<span className='flex items-center ml-6 h-7'>
															<ChevronDownIcon
																className={classNames(
																	open
																		? '-rotate-180'
																		: 'rotate-0',
																	'h-6 w-6 transform transition duration-150 ease-in-out'
																)}
																aria-hidden='true'
															/>
														</span>
													</Disclosure.Button>
												</dt>
												<Disclosure.Panel
													as='dd'
													className='pr-12 mt-2 mb-4'
												>
													<p className='text-base text-gray-300'>
														Visit our{' '}
														<a
															className='text-white underline hover:text-gray-200'
															href='https://opensea.io/collection/metatravelers'
															target='_blank'
															rel='noreferrer'
														>
															OpenSea Collection
														</a>
													</p>
												</Disclosure.Panel>
											</>
										)}
									</Disclosure>
								</dl>
							</div>
						</Fade>
					</div>
				</div>
			</div>
			<Fade direction='up' delay={300} triggerOnce>
				<img
					src={nbru_one}
					alt='Nibiru 1'
					style={{ width: '440px' }}
					className='absolute bottom-0 right-0 z-0 hidden transform 2xl:right-32 xl:block '
				/>
			</Fade>
			<Fade direction='up' delay={200} triggerOnce>
				<img
					src={nbru_three}
					alt='Nibiru 2'
					style={{ width: '440px' }}
					className='absolute bottom-0 left-0 z-0 hidden transform 2xl:left-32 xl:block '
				/>
			</Fade>
		</div>
	)
}
