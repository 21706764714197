import { Link } from 'react-router-dom'
import { PopupButton } from '@typeform/embed-react'

export default function SmartContractDev() {
	return (
		<div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
			<div className='flex justify-between pt-8'>
				<Link
					to='/join-the-team'
					className='text-xl font-semibold tracking-wide text-white transition-all duration-200 cursor-pointer orbitron-no-glow hover:text-gray-300'
				>
					Go back
				</Link>
			</div>
			<div className='py-28'>
				<div className='flex justify-between'>
					<div className='text-5xl font-semibold tracking-widest text-white orbitron-no-glow'>
						Smart Contract Developer
					</div>
					<PopupButton
						id='jNf6cEsE'
						className='px-4 py-5 my-auto font-bold tracking-widest text-white general-button orbitron-no-glow'
					>
						Apply Now
					</PopupButton>
				</div>
				<div className='text-gray-300'>
					<div className='pt-16 '>
						MetaTravelers is looking for an awesome Smart Contract
						Developer!
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Role type:
						</span>
						<br />
						Contract to start, with potential to grow into a
						full-time position as the organization grows.
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Required Skills:
						</span>
						<br />
						Experience developing and deploying Solidity Smart
						Contracts to the Ethereum blockchain and familiarity
						with ERC-20 & ERC-721 Token standards. Experience with
						Hardhat development environment.
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Additional Desired Skills and Experience in:
						</span>
						<br />
						Additional experience with proxy or upgradable contracts
						is a plus.
					</div>
					<div className='pt-10'>
						<span className='font-semibold text-gray-100'>
							Responsibilities and Duties:
						</span>
						<br />
						- Follows smart contract coding best practices, which
						includes clear code commenting (NatSpec format),
						documentation, and full unit test coverage
						<br />
						- Demonstrate clear and collaborative communication
						within the development team
						<br />
						- Attendance and participation in daily/weekly status
						meetings
						<br />
						- Ability to receive and respond to constructive
						criticism
						<br />- Timely completion of tasks/be able to respond to
						and address urgent tasks with a quick turnaround to meet
						high priority deadlines
					</div>
				</div>
			</div>
		</div>
	)
}
