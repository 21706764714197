import { Fade } from 'react-awesome-reveal'

import metaDiscord from '../assets/metaDiscord.png'

export default function DiscordBox() {
	return (
		<div className='fixed bottom-0 right-0 z-40 hidden mb-4 mr-6 text-gray-300 md:block hover:text-white'>
			<Fade direction='right' triggerOnce>
				<a
					href='http://discord.gg/QT5q6Nfbqb'
					target='_blank'
					rel='noreferrer'
				>
					<div
						className='flex flex-col w-40 h-32 text-center shadow-xl discord-box-mixin '
						data-augmented-ui='tr-clip bl-clip tl-clip br-clip   both'
					>
						<div className='pt-2 pb-2 text-lg font-semibold tracking-wide orbitron-no-glow '>
							Join our Discord
						</div>

						{/* <i className='fab fa-discord fa-2x'></i> */}
						<div className='flex justify-center'>
							<img
								className='w-10 '
								src={metaDiscord}
								alt='Meta Discord'
							/>
						</div>
					</div>
				</a>
			</Fade>
		</div>
	)
}
