import { Dialog, Transition, Tab } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import ep1 from '../assets/yt_thumbnails/yt_nibiru-ep1.jpg'
import ep2 from '../assets/yt_thumbnails/yt_nibiru-ep2.jpg'
import ep3 from '../assets/yt_thumbnails/yt_nibiru-ep3.jpg'
import ep4 from '../assets/yt_thumbnails/yt_nibiru-ep4.jpg'
import ep5 from '../assets/yt_thumbnails/yt_nibiru-ep5.jpg'
import ep6 from '../assets/yt_thumbnails/yt_nibiru-ep6.jpg'

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function OriginModal() {
	let [categories] = useState({
		'Ep 1': [
			{
				id: 1,
				img: ep1,
				url: 'https://www.youtube.com/watch?v=1Jb-QCf9Oac'
			}
		],
		'Ep 2': [
			{
				id: 2,
				img: ep2,
				url: 'https://www.youtube.com/watch?v=S3nPCjgpH0M'
			}
		],
		'Ep 3': [
			{
				id: 3,
				img: ep3,
				url: 'https://www.youtube.com/watch?v=azAKtSjidzM'
			}
		],
		'Ep 4': [
			{
				id: 4,
				img: ep4,
				url: 'https://www.youtube.com/watch?v=oMsbljJCID0'
			}
		],
		'Ep 5': [
			{
				id: 5,
				img: ep5,
				url: 'https://www.youtube.com/watch?v=zOjeNo_-eSY'
			}
		],
		'Ep 6': [
			{
				id: 6,
				img: ep6,
				url: 'https://www.youtube.com/watch?v=w0EfLOO-feM'
			}
		]
	})

	let [isOpen, setIsOpen] = useState(false)

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	return (
		<>
			<div className='inset-0 flex items-center justify-center '>
				<motion.button
					whileHover={{ boxShadow: '0 0 8px #fff' }}
					onClick={openModal}
					className='px-4 py-5 my-4 text-base cta-button orbitron'
				>
					Watch Videos
				</motion.button>
			</div>

			<Transition appear show={isOpen} as={Fragment}>
				<Dialog
					as='div'
					className='fixed inset-0 z-50 overflow-y-auto'
					onClose={closeModal}
				>
					<div className='min-h-screen px-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<Dialog.Overlay className='fixed inset-0' />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className='inline-block h-screen align-middle'
							aria-hidden='true'
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<div
								className='inline-block w-full max-w-3xl p-6 pb-16 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl discord-box-mixin'
								data-augmented-ui='tr-clip bl-clip tl-clip br-clip   both'
							>
								<Dialog.Title
									as='h3'
									className='flex justify-between text-3xl font-medium leading-6 text-white orbitron'
								>
									<div></div>
									<button
										type='button'
										className='inline-flex justify-center px-4 py-2 my-4 text-xl font-medium text-purple-900 bg-indigo-200 border border-transparent rounded-md hover:bg-indigo-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500'
										onClick={closeModal}
									>
										Close Window
									</button>
								</Dialog.Title>

								<Tab.Group>
									<Tab.List className='flex flex-col p-1 space-x-1 md:flex-row rounded-xl'>
										{Object.keys(categories).map(
											category => (
												<Tab
													key={category}
													className={({ selected }) =>
														classNames(
															'  w-full py-2.5 text-xl md:text-3xl leading-5 font-medium mb-4',
															'',
															selected
																? ' orbitron text-white border-b-2'
																: 'orbitron-no-glow text-gray-400'
														)
													}
												>
													<div className='pb-2'>
														{category}
													</div>
												</Tab>
											)
										)}
									</Tab.List>
									<Tab.Panels className='mt-2'>
										{Object.values(categories).map(
											(posts, idx) => (
												<Tab.Panel key={idx}>
													<ul>
														{posts.map(post => (
															<div key={post.id}>
																<div className='flex justify-center'>
																	<a
																		href={
																			post.url
																		}
																		target='_blank'
																		rel='noreferrer'
																	>
																		<LazyLoadImage
																			effect='blur'
																			src={
																				post.img
																			}
																			alt='thumbnail'
																		/>
																	</a>
																</div>
															</div>
														))}
													</ul>
												</Tab.Panel>
											)
										)}
									</Tab.Panels>
								</Tab.Group>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
