import { Link } from 'react-router-dom'
import Menu from './Menu'
import mtlogo from '../assets/mt_logos/mt_mark-white.png'
import mtText from '../assets/mtText.png'

import { useState, useEffect } from 'react'

// import { Fade } from 'react-awesome-reveal'

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

interface ConnectWallet {
	connectWallet(): Promise<any>
	displayAccount: any
}

const Navbar: React.FC<ConnectWallet> = ({ connectWallet, displayAccount }) => {
	const [navbar, setNavbar] = useState(false)
	// const [logoText, setLogoText] = useState(false)

	const changeBackground = () => {
		if (window.scrollY >= 66) {
			setNavbar(true)
		} else {
			setNavbar(false)
		}
	}

	// const showText = () => {
	// 	if (window.scrollY >= 600) {
	// 		setLogoText(true)
	// 	} else {
	// 		setLogoText(false)
	// 	}
	// }

	useEffect(() => {
		changeBackground()
		window.addEventListener('scroll', changeBackground)
		// changeBackground()
		// window.addEventListener('scroll', showText)
	})

	return (
		<div
			className={classNames(
				navbar ? 'navbar active' : 'navbar mt-14 md:my-8',
				'fixed z-40 flex justify-between w-full px-4 py-6 mx-auto sm:px-6 lg:py-6 lg:px-8 '
			)}
		>
			<div className='flex flex-row items-center'>
				<a href='/'>
					<img
						className='h-auto mt-2 w-14'
						src={mtlogo}
						alt='MetaTravelers'
					/>
				</a>
				<Link
					className='flex items-center px-4 tracking-wider text-white link-orbitron'
					to='/join-the-team'
				>
					We're Hiring!
				</Link>
			</div>
			{/* {logoText ? (
				<Fade> */}
			<Link to='/'>
				<img
					className='absolute hidden h-10 mt-4 lg:block -ml-52 left-1/2'
					src={mtText}
					alt='MetaTravelers'
				/>
			</Link>
			{/* </Fade>
			) : (
				<></>
			)} */}

			<div className='flex flex-row'>
				<a
					href='https://view.metatravelers.io'
					className='flex items-center px-4 tracking-wider text-white link-orbitron'
				>
					Visit 3D Viewer
				</a>
				<Menu />
			</div>
		</div>
	)
}

export default Navbar
