import { motion } from 'framer-motion'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import avatarLava from '../assets/AvatarLava.png'

import metaDiscord from '../assets/metaDiscord.png'

export default function Banner() {
	return (
		<div className='relative overflow-hidden'>
			<img
				loading='lazy'
				src={avatarLava}
				alt='MetaTravelers Logo'
				style={{ width: '500px' }}
				className='absolute top-0 right-0 z-0 hidden transform 2xl:right-64 lg:block '
			/>
			<div className='py-24 bg-indigo-900 '>
				<div className='px-4 mx-auto text-center lg:text-left max-w-7xl sm:px-6 lg:px-8 lg:flex lg:items-center'>
					<div className='lg:w-0 lg:flex-1'>
						<div className='flex items-center '>
							<LazyLoadImage
								effect='blur'
								src={metaDiscord}
								className='w-16 mr-4'
								alt='Meta Discord'
							/>
							<h2
								className='text-3xl font-extrabold tracking-tight text-white orbitron-no-glow sm:text-4xl'
								id='newsletter-headline'
							>
								Join the community
							</h2>
						</div>
						<p className='my-4 text-base text-gray-300 lg:w-1/2 '>
							Get involved by joining our discord community and
							stay up to date with exclusive announcements,
							giveaways and events!
						</p>
						<a
							href='http://discord.gg/QT5q6Nfbqb'
							target='_blank'
							rel='noreferrer'
						>
							<motion.button
								whileHover={{ boxShadow: '0 0 8px #fff' }}
								className='px-4 py-5 mt-4 cta-button orbitron'
							>
								Join our Discord
							</motion.button>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
