import {
	useState
	// useEffect
} from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ethers } from 'ethers'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Navbar from './Navbar'
import Hero from './Hero'
import Stats from './Stats'
import Description from './Description'
import Collection from './Collection'
import Roadmap from './Roadmap'
import FAQ from './FAQ'
import Team from './Team'
import Partners from './Partners'
import Banner from './Banner'
import Footer from './Footer'
import DiscordBox from './DiscordBox'
import JoinTheTeam from './JoinTheTeam'
import SmartContractDev from './JoinTheTeam/SmartContractDev'
import ThreeDGeneralist from './JoinTheTeam/ThreeDGeneralist'
import UnrealEngineDev from './JoinTheTeam/UnrealEngineDev'
import CommunityManager from './JoinTheTeam/CommunityManager'

declare global {
	interface Window {
		ethereum: any
	}
}

// mainnet
// const CONTRACT_ADDRESS = '0x5372f926b34Be60aC1436372107C3eE8c6e056E5'

export default function App() {
	const [currentAccount, setCurrentAccount] = useState('')
	const [displayAccount, setDisplayAccount] = useState('')

	const connectWallet = async (): Promise<any> => {
		const { ethereum } = window

		if (!ethereum) {
			toast.error('Please install MetaMask', {
				position: 'bottom-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			})
			return
		}
		try {
			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: '0x1' }]
			})
		} catch (error) {
			toast.error('Must switch to Mainnet', {
				position: 'bottom-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			})
		}
		try {
			const provider = new ethers.providers.Web3Provider(window.ethereum)
			await provider.send('eth_requestAccounts', [])
			const signer = provider.getSigner()
			setCurrentAccount(await signer.getAddress())
			minifyDisplayAccount(await signer.getAddress())
			console.log('currentAccount', currentAccount)
			// const contract = new ethers.Contract(
			// 	CONTRACT_ADDRESS,
			// 	metaTravelersJSON.abi,
			// 	signer
			// )
		} catch (error) {
			toast.error('Please login to MetaMask or refresh', {
				position: 'bottom-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			})
			console.log('error', error)
			setDisplayAccount('Waiting...')
		}
	}

	const minifyDisplayAccount = (address: string) => {
		let beginning = address.slice(0, 6)
		let end = address.slice(address.length - 4, address.length)
		setDisplayAccount(beginning + '...' + end)
	}

	return (
		<>
			<Router>
				<Routes>
					<Route
						path='/'
						element={
							<>
								<div className='w-screen '>
									<Navbar
										connectWallet={connectWallet}
										displayAccount={displayAccount}
									/>
									<div className='bg-indigo-900'>
										<Hero />
										<Stats />
									</div>
									<Description />
									<Collection />
									<Roadmap />
									<FAQ />
									<Team />
									<Partners />
									<Banner />
									<Footer />
									<DiscordBox />
								</div>
								<ToastContainer
									style={{ width: '500px' }}
									limit={3}
									position='bottom-center'
									autoClose={5000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme={'colored'}
								/>
							</>
						}
					/>
					<Route path='/join-the-team' element={<JoinTheTeam />} />
					<Route
						path='/join-the-team/smart-contract-developer'
						element={<SmartContractDev />}
					/>
					<Route
						path='/join-the-team/3d-generalist'
						element={<ThreeDGeneralist />}
					/>
					<Route
						path='/join-the-team/unreal-engine-developer'
						element={<UnrealEngineDev />}
					/>
					<Route
						path='/join-the-team/community-manager'
						element={<CommunityManager />}
					/>
				</Routes>
			</Router>
		</>
	)
}
